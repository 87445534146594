<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      left
      offset-x
  >

    <template #activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          color="blue darken-1"
          text
          v-on="on"
          @click="suggest()"

      >
        {{ bid ? `$${bid}` : 'suggest' }}
      </v-btn>
    </template>
    <v-data-table
        :items="items"
        :headers="headers"
        show-expand
        :loading="loading"
        loading-text="Loading profiles ... Please wait"

    >


      <template #[`item.actions`]="{ item }">
        <v-btn
            color="blue darken-1"
            text
            @click="use(item)"
        >USE
        </v-btn>
      </template>
      <template #[`expanded-item`]="{ headers, item }">
        <td :colspan="headers.length">
          <div v-for="val in Object.keys(item.profile)" :key="val + Math.random()">{{ val }}:
            {{ item.profile[val] }}
          </div>
        </td>
      </template>
    </v-data-table>
  </v-menu>
</template>

<script>
import api from "@/api/backend-api";

export default {
  props: ["domain"],
  data: () => ({
    menu: false,
    loading: true,
    stats: [],
    evaluation: null,
    bid: 0,
  }),
  computed: {

    items() {
      let items = [];
      if (!this.loading) {
        for (const stats of this.stats) {
          let item = {};
          item.successRate = Number.parseFloat(stats.soldCount / stats.casesCount * 100).toFixed(2);
          item.profitRate = 500;
          item.uncertainty = stats.uncertainty;
          item.bid = Math.round(this.evaluation.price / item.profitRate * item.successRate);
          item.profile = stats.salesProfile;
          item.profileHash = stats.salesProfile.profileHash;
          item.difference = stats.difference;
          items.push(item);
        }
      }
      return items;
    },
    headers() {
      return [
        {text: "Success", value: "successRate"},
        {text: "Profit %", value: "profitRate"},
        {text: "Uncertainty", value: "uncertainty"},
        {text: "Difference", value: "difference"},
        {text: "Hash", value: "profileHash"},
        {text: "Bid", value: "bid"},
        {test: "Actions", value: "actions", sortable: false},
        {text: "", value: "data-table-expand", sortable: false},
      ];
    },
  },
  methods: {
    async suggest() {
      this.loading=true;
      const payload = {
        domainName: this.domain,
      }
      const {data} = await api.suggestBet(payload);
      console.log("Received bid suggestion on ", this.domain, data);
      this.stats = data.statistics;
      this.evaluation = data.evaluation;
      this.loading=false;
    },
    use(item){
      this.$emit('updateBid', item.bid>59?59:item.bid);
    }
  },
}
</script>

<style></style>
