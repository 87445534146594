<template>
    <v-data-table
        dense
      :items="conversations"
      :headers="headers"
      :options.sync="options"
      :search="search"
      :server-items-length="totalConversations"
      sort-by="lastUpdated"
      :sort-desc="true"
      :items-per-page="itemsPerPage || 50"
      show-expand
      :loading="loading"
      loading-text="Loading... Please wait"
      :expanded.sync="expanded"
      :item-class="itemRowBackground"
      @item-expanded="handleExpand"
    >
      <template #top>
        <v-row
          no-gutters
          justify="space-around"
          align="center"
        >
          <v-col cols="12" md="2">

            <v-btn
              block
              @click="changeConversationsType"
            >
              {{
                answered ? "SHOW OPEN" : "SHOW ALL"
              }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="search"
              label="Domain"
              class="mx-4"
              clearable
            />
          </v-col>
          <v-col cols="12" md="2">

            <v-select
              v-model="conversation_state"
              :items="conversation_states"
              label="Conversation"
            />
          </v-col>
          <v-col cols="12" md="2">

            <v-menu
              v-model="menu"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormatted"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="dateFormatted"
                no-title
                scrollable
              />
            </v-menu>
          </v-col>
        </v-row>
      </template>
      <template #[`item.dropDate`]="{ item }">
        <date-display :date="item.lead.dropDate ? item.lead.dropDate : ''" />
      </template>
      <template #[`item.suggestedPrice`]="{ item }">
        <suggest-price-btn
          :domain-name="item.lead.domain"
            :suggested-price="item.suggestedPrice"
        />
      </template>
      <template #[`item.state`]="{ item }">
        <v-select
          v-model="item.state"
          :items="conversation_stages"
          @change="updateStage(item)"
        />
      </template>
      <template #[`item.lastUpdated`]="{ value }">
        <date-display
          :date="value"
          date-format="MMM DD HH:mm:ss"
        />
      </template>
      <template #[`item.phone`]="{ item }">
        <div @click="copy(item.lead && item.lead.phone ? item.lead.phone : '')">
          {{ item.lead && item.lead.phone ? item.lead.phone : "" }}
        </div>
      </template>
      <template #[`item.domainMenu`]="{ item }">
        <domain-menu
          v-if="item.lead"
          :name="item.lead.domain"
        />
      </template>
      <template #[`item.externalSiblingsCount`]="{ item }">
        <domain-card
          :domain-name="item.lead.domain"
          :external-siblings-count="item.externalSiblingsCount"
        />
      </template>
      <template #[`item.domainAge`]="{ item }">
        {{ item.domainAge }}y
      </template>
      <template #[`item.actions`]="{ item }">
        <v-row>
          <backorder-menu-small
            v-if="item.state === 'backorder'"
            :domain="item.lead.domain"
          />
          <list-domain-menu-small
            v-if="item.state === 'pending sale'"
            :domain-name="item.lead.domain"
          />

          <v-btn
            fab
            x-small
            @click="rescan(item)"
          >
            <v-icon>
              mdi-redo
            </v-icon>
          </v-btn>
          <v-btn
            fab
            x-small
            @click="hide(item)"
          >
            <v-icon >
              mdi-close-circle-outline
            </v-icon>
          </v-btn>
          <v-btn

              @click="block(item)"
          >
          block
          </v-btn>
        </v-row>
      </template>
      <template #[`expanded-item`]="{ headers, item }">
        <td :colspan="headers.length">
          <conversation-card
            :conversation="item"
            :show-form="showMessageForm"
            kind="response"
          />
        </td>
      </template>
    </v-data-table>
</template>

<script>
import DateDisplay from "@/components/DateDisplay";
import ConversationCard from "@/components/conversations/ConversationCard";
import SiblingMenu from "@/components/SiblingMenu";
import DomainMenu from "@/components/DomainMenu";
import BackorderMenuSmall from "@/components/BackorderMenuSmall";
import ListDomainMenuSmall from "@/components/ListDomainMenuSmall";
import SuggestPriceBtn from "@/components/EvaluationMenu"

import tools from "@/util/tools";

import { mapActions, mapState, mapGetters } from "vuex";
import DomainCard from "../domains/DomainCard.vue";

export default {
  components: {
    DateDisplay,
    ConversationCard,
    DomainMenu,
    DomainCard,
    BackorderMenuSmall,
    ListDomainMenuSmall,
    SuggestPriceBtn,
  },

  props: ["showMessageForm", "id", "sibling"],
  data() {
    return {
      search: "",
      options: {},
      itemsPerPage: 50,
      awaitingSearch: false,
      conversation_state: "",
      answered: true,
      dropDate: null,
      menu: false,
    };
  },
  computed: {
    ...mapState("conversations", ["conversation_states", "message_types"]),

    ...mapGetters({
      conversations: "conversations/getSortedConversations",
    }),
    dateFormatted: {
      get() {
        if (!this.dropDate) return null;
        return new Date(this.dropDate).toISOString().substr(0, 10);
      },
      set(value) {
        this.dropDate = new Date(value);
      },
    },
    headers() {
      return [
        { text: "#", value: "phone" },
        { text: "Drop Date", value: "dropDate"},
        { text: "D", value: "domainMenu"},
        { text: "Price", value: "suggestedPrice"},
        { text: "Sib#", value: "externalSiblingsCount"},
        { text: "Age", value: "domainAge"},
        { text: "Stage", value: "state"},
        { text: "Last message", value: "lastUpdated"},
        { text: "Actions", value: "actions", sortable: false},
        { text: "", value: "data-table-expand",  sortable: false},
      ];
    },
    expanded: {
      get() {
        return this.conversations.filter((item) => !item.answered);
      },
      set(value) {},
    },
    loading: {
      get() {
        return this.$store.state.conversations.loading;
      },
      set(val) {
        this.$store.commit("conversations/SET_LOADING", val);
      },
    },
    totalConversations() {
      return this.$store.state.conversations.totalItems;
    },
    searchCriteria() {
      return `${this.search}|${this.conversation_state}|${this.answered}|${this.dropDate}`;
    },
  },
  watch: {
    options: {
      handler(val) {
        console.log(
          "Options: ",
          val,
          " id: ",
          this.id,
          "conversation_state: ",
          this.conversation_state
        );
        if (!this.id) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    searchCriteria: {
      handler(val) {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getDataFromApi();
            this.awaitingSearch = false;
          }, 1000); // 1 sec delay
        }
        this.awaitingSearch = true;
      },
    },
  },

  async created() {
    // await this.$store.dispatch("conversations/loadAllConversations");
  },
  methods: {
    ...mapActions("conversations", [
      "rescanDomainAction",
      "hideConversationAction",
      "updateAction",
      "search",
    ]),

    changeConversationsType() {
      this.answered = !this.answered;
    },
    itemRowBackground(item) {
      let style = this.pastCutOff(item)?"pastcutoff"
              :item.state==="not interested"?"not-interested"
                :item.answered ? "answered" :"not-answered";
      return style;
    },
    pastCutOff(item) {
      return new Date(item.lead.dropDate)<new Date();
    },
    async getDataFromApi() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const direction = sortDesc[0] ? "DESC" : "ASC";
      const size = itemsPerPage;

      let sort = "lastUpdated,DESC";
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0] + "," + direction;
      }

      await this.search({
        searchDomain: this.search,
        dropDate: this.dateFormatted,
        state: this.conversation_state === "all" ? "" : this.conversation_state,
        answered: this.answered,
        page: page - 1,
        sort,
        size,
      });
    },
    updateStage(item) {
      const payload = {
        id: item.id,
        state: item.state,
      };
      console.log("Sending update ", payload);
      this.updateAction(payload);
    },
    handleExpand(event) {
      console.log("Got expand event: ", event);
      let conversation = {
        id: event.item.id,
        answered: !event.value,
      };
      event.item.answered = !event.value;
      this.updateAction(conversation);
    },
    hide(item) {
      let conversation = {
        id: item.id,
        state: item.state,
      };
      this.hideConversationAction({id: conversation.id, block: false});
    },
    block(item) {
      let conversation = {
        id: item.id,
        state: "item.state",
      };
      this.hideConversationAction({id: conversation.id, block: true});
    },
    rescan(item) {
      this.rescanDomainAction(item.lead.domain);
    },
    async copy(link) {
      await tools.copyTextToClipboard(link.toLowerCase());
      this.$store.dispatch("snackbar/setSnackbar", {
        data:{
          'Copied': link,
        },
        color: "blue",
      });
    },
    goToConversation(item) {
      // this.$router.push(`/conversation/${item.id}`).catch(()=>{});
      this.$emit("conversation-pick", item.id);
    },
    openBuiltWith(item){
          let target = `https://builtwith.com/${item.domain.name}`;
          window.open(target, "_blank", "noreferrer");
    }
  },
};
</script>

<style>
.conversations {
  max-width: 90%
}
.answered {
  background-color: #4caf50;
}
.not-answered {
  background-color: #b71c1c;
}
.pastcutoff {
  background-color: #fffb00;
}
.not-interested {
  background-color: #5e8a9e;
}
tr {
  padding: 0px 0px;
}
</style>
