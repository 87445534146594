<template>
  <v-menu
    v-if="domain"
    v-model="menu"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        tile
        v-on="on"
        x-small
        class="mb-1"
      >
        <v-icon>mdi-cart-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>{{ domain.name }}</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="bid"
          :rules="priceRules"
          label="Bid*"
          required
        />
      </v-card-text>
      <v-card-actions>
        <suggest-bid-menu
            :domain="domain"
            @updateBid="updateBid"
        />

        <v-btn
          color="blue darken-1"
          text
          @click="backorder"
        >
          Send
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="menu = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";
import SuggestBidMenu from "@/components/SuggestBidMenu";
export default {
  components: {SuggestBidMenu},
  props: ["domain"],
  data: () => ({
    menu: false,
    bid: "",
    priceRules: [
      (value) => !!value || "Required.",
      (value) => {
        const pattern = /^[0-9]+$/;
        return pattern.test(value) || "Invalid number.";
      },
    ],
  }),
  methods: {
    ...mapActions("backorders", ["backorderAction"]),
    backorder() {
      let backorder = {
        bid: this.bid,
        domainName: this.domain,
      };
      this.backorderAction(backorder);
      this.menu = false;
    },
    updateBid(value){
      this.bid=value;
    }
  },
};
</script>

<style></style>
