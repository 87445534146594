<template>
  <conversations-table
    :id="id"
    :show-message-form="true"
  />
</template>

<script>

import {mapGetters} from "vuex";
import ConversationsTable from '../components/conversations/ConversationsTable.vue';

export default {
  components: {
    ConversationsTable,
  },
  data() {
    return {
      id: Number,
      loading: true,
      error: null,
      conversation: {},
    };
  },
  computed: {
    ...mapGetters("conversations", ["getConversationById"]),
  },

  created() {
    this.id = this.$route.params.id;
    this.retrieveConversation();
  },

  methods: {
    async retrieveConversation() {
      
      // const data = await conversationAPI.loadById(this.id);
      // console.log("Adding conversation: ", data);
      // this.conversation=data;
      console.log("loading conversation with id: ", this.id);
      await this.$store.dispatch(
        "conversations/loadConversationByIdAction",
        this.id
      );
    },
  },
};
</script>

<style></style>
